import { ModelComparator } from '@utils/model-comparator/model-comparator';
import { LocalizedName, NamedItem } from './named-item.type';

export type StatusList = Array<Status>;

export type StatusColor = {
  border: string;
  color: string;
  hover: string;
};

export type StatusColorList = Array<StatusColor>;

export type StatusColorResponse = {
  colors: StatusColorList;
};

export type Status = {
  id: number;
  name: string;
  description: string;
  color: string;
  border_color: string;
  hover_color: string;
  icon: string;
  prev_product_statuses: NamedItem[] | [];
  next_product_statuses: NamedItem[] | [];
  is_default: boolean;
  active: boolean;
};

export type StatusForm = {
  name: string;
  previousStatuses: ProductStatus[];
  description: string;
  color: string;
  icon: string;
  orders: boolean;
  ordersWarehouses: boolean;
  shelfSpace: boolean;
  lostSales: boolean;
  automaticallyChanged: boolean;
  automaticallyChangedWarehouse: boolean;
  toStatusWarehouse: NamedItem;
  toStatus: NamedItem;
  notification: boolean;
  limitInDays: boolean;
  amountOfDays: number;
  orderType: LocalizedName;
};

export const LIMIT_IN_DAYS = 90;

export const DEFAULT_STATUS_VALUE: StatusForm = {
  name: '',
  previousStatuses: [],
  description: '',
  color: '',
  icon: '',
  orders: true,
  ordersWarehouses: true,
  shelfSpace: true,
  lostSales: true,
  automaticallyChanged: false,
  toStatus: { id: 0, name: '' },
  automaticallyChangedWarehouse: false,
  toStatusWarehouse: { id: 0, name: '' },
  notification: false,
  limitInDays: false,
  amountOfDays: LIMIT_IN_DAYS,
  orderType: { name: '', localized_name: '' },
};

export type ProductStatus = {
  id: number;
  product_status: {
    active: boolean | undefined;
    id: number;
    name: string;
  };
};

export type ExtendedStatus = {
  active: boolean;
  amount_of_days: number;
  automatically_changed: boolean;
  automatically_changed_no_warehouse: boolean;
  color: string;
  description: string;
  icon: string;
  id: number;
  is_default: boolean;
  limit_in_days: boolean;
  name: string;
  notification_when_inventories_empty: boolean;
  orders: boolean;
  order_to_warehouses: boolean;
  next_product_statuses: ProductStatus[];
  prev_product_statuses: ProductStatus[];
  shelf_space: boolean;
  lost_sales: boolean;
  to_status: NamedItem;
  order_type_status: LocalizedName;
};

export type ProductStatusAttributes = { id: number; prev_status_id: number; _destroy: boolean };

export class StatusSaveRequest {
  name: string;
  description: string;
  color: string;
  icon: string;
  prev_product_statuses_attributes: ProductStatusAttributes[];
  orders: boolean;
  order_to_warehouses: boolean;
  shelf_space: boolean;
  lost_sales: boolean;
  automatically_changed: boolean;
  automatically_changed_no_warehouse: boolean;
  to_status_id: number | null;
  notification_when_inventories_empty: boolean;
  limit_in_days: boolean;
  amount_of_days: number;
  order_type_status: string;

  constructor(form: StatusForm, productPrevStatusesCopy: ProductStatus[]) {
    this.name = form.name;
    this.description = form.description || '';
    this.color = form.color || '';
    this.icon = form.icon || '';
    this.prev_product_statuses_attributes = new ModelComparator<ProductStatusAttributes, 'prev_status_id'>()
      .byKey('prev_status_id')
      .models(
        productPrevStatusesCopy.map((s) => ({ id: s.id, prev_status_id: s.product_status.id, _destroy: false })),
        form.previousStatuses.map((s) => ({ id: s.id, prev_status_id: s.product_status.id, _destroy: false })),
      );
    this.orders = form.orders;
    this.order_to_warehouses = form.ordersWarehouses;
    this.shelf_space = form.shelfSpace;
    this.lost_sales = form.lostSales;
    this.automatically_changed = form.automaticallyChanged;
    this.automatically_changed_no_warehouse = form.automaticallyChangedWarehouse;
    this.to_status_id =
      form.automaticallyChanged ? form.toStatus.id
      : form.automaticallyChangedWarehouse ? form.toStatusWarehouse.id
      : null;
    this.notification_when_inventories_empty = form.notification;
    this.limit_in_days = form.limitInDays;
    this.amount_of_days = form.amountOfDays;
    this.order_type_status = form.orderType.localized_name;
  }
}
